import React, { PureComponent } from 'react';
import Dialog from '@mui/material/Dialog';
import Content from './Content';

class DialogAdvancePayment extends PureComponent {
  render() {
    const { open, ...props } = this.props;

    return (
      <Dialog open={open} onClose={this.onClose} fullWidth maxWidth='md'>
        <Content {...props}/>
      </Dialog>);
  }
}

export default DialogAdvancePayment;
