import React, { Component } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from 'tss-react/mui';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { stringToTime, timeToString } from '../utils/date';

const styles = {
  menuHeight: {
    maxHeight: 300
  },
  full: {
    width: '100%'
  }
};

export class TimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = this.timeRangeData({
      start: props.start || '00:00',
      end: props.end || '23:59',
      step: props.step || 15,
      timeRange: [],
    });
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.value !== this.props.value);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let obj = {
      start: nextProps.start || this.state.start,
      end: nextProps.end || this.state.end,
      step: nextProps.step || this.state.step,
      timeRange: [],
    };
    this.setState(this.timeRangeData(obj));
  }

  timeRangeData(obj) {
    let start = stringToTime(obj.start);
    const end = stringToTime(obj.end);

    while (start < end)
    {
      obj.timeRange.push(timeToString(start));
      start += obj.step;
    }
    return obj;
  }

  render() {
    const {
      name,
      value,
      onChange,
      label,
      classes,
      number,
      size,
      marginLeft,
      labelRemoveSpace
    } = this.props;
    const {
      timeRange
    } = this.state;
    return (
      <FormControl className={classes.full}>
        <InputLabel
          style={{ whiteSpace: labelRemoveSpace ? 'nowrap' : 'initial' }}
        >{label}</InputLabel>
        <Select
          value={value}
          style={{ width: size || 275, marginLeft }}
          onChange={(event) => {
            event.target.name = name;
            return onChange(event);
          }}
          MenuProps={{
            classes: { paper: classes.menuHeight },
          }}
        >
          {timeRange.map((elem, id) => (
            <MenuItem key={id} value={number ? id * 15 : elem}>
              {elem}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(TimePicker, styles);
