import React, { PureComponent } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';

class ActionNotification extends PureComponent {
  constructor() {
    super();
    this.state = {
      open: false,
      message: '',
      cb: null
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleAction = this.handleAction.bind(this);
  }
  //TODO create open condition
  handleOpen(message, cb) {
    this.setState({
      open: true,
      message: message,
      cb: cb
    });
  }

  handleClose() {
    this.setState({
      open: !this.state.open
    });
  }

  handleAction() {
    this.state.cb();
    this.setState({
      open: !this.state.open,
      message: '',
      cb: null
    });
  }

  render() {
    const {
      open,
      message,
    } = this.state;

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        message={<span>{message}</span>}
        action={
          [
            <Button variant="contained"
              key="undo"
              color="secondary"
              size="small"
              onClick={this.handleAction}
            >
              Rester co.
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <Close/>
            </IconButton>,
          ]
        }
      />
    );
  }
}

export default ActionNotification;
