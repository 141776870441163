import React from 'react';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const mapStateToProps = ({
  dialog: {
    contentProps: {
      defaultText,
    },
  },
}) => ({
  defaultText,
});


const ConfirmDialog = ({
  defaultText = { subtitle: '' },
}) => <Grid container justifyContent="center">
  <Grid item xs={12}>
    {!!defaultText.subtitle &&
      defaultText.subtitle.split('\n')
        .map((e, i) => <Typography key={i} align="center" children={e} />)}
  </Grid>
</Grid>;

export default connect(
  mapStateToProps,
)(ConfirmDialog);
