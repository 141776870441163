import React, { Component } from 'react';
import api from '../../api';
import { login as loginAction } from '../../actions/auth';
import notifSystem from '../../notifSystem';

import { moment } from '@lba-dev/package.local-globals/moment';

import ChangePassword from '../UserConnect/ChangePassword';
import LoginDialog from '../UserConnect/LoginDialog';
import ForgetPassword from '../UserConnect/ForgetPassword';
import queryString from 'query-string';
import withRouter from '../Hoc/withRouter';
import { connect } from 'react-redux';
import { compose } from 'redux';

moment.locale('fr');

const mapStateToProps = (state) => ({
  auth: state.auth,
  pages: (state.auth.decodedToken || {}).pages || []
});

class LoginId extends Component {
  state = {
    tel: '',
    pass: '',
    open: true,
    redirect: false,
    forgetPassword: false,
    verifnewPassword: '',
    newPassword: '',
    changePassword: false,
    codeVerif: '',
    elem: {},
    isUnique: false,
    loggedOnce: this.props.auth.token !== undefined
  };

  componentDidMount = () => {
    if (this.state.loggedOnce) {
      return this.props.navigate(
        location?.state?.from?.pathname &&
        location?.state?.from?.search
          ? `${location.state.from.pathname}${
            location.state.from.search}`
          : '/',
        { state: { from: '/' } }
      );
    }
    if (this.props.location.search) {
      const query = queryString.parse(this.props.location.search);
      if (query.password) {
        this.setState(
          {
            pass: query.password,
            tel: query.telephone,
          },
          this.login
        );
      }
    }
  };

  login = async () => {
    const { tel, pass, newPassword } = this.state;
    const {
      location,
      navigate
    } = this.props;
    try {
      const res = await api.login.post({
        login: tel,
        pass: newPassword !== '' ? newPassword : pass,
      });
      if (res) {
        const { token } = res.body().data();
        this.props.dispatch(loginAction(token));
        navigate(
          location?.state?.from?.pathname &&
          location?.state?.from?.search
            ? `${location.state.from.pathname}${
              location.state.from.search}`
            : '/',
          { state: { from: '/' } }
        );
      }
    } catch (e) {
      notifSystem.error('Erreur', 'Echec connexion');
    }
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  forgetPassword = async () => {
    const { tel } = this.state;
    try {
      if (tel === '') {
        throw Error();
      }
      await api.login.custom('forgetPassword').post({ tel });
      this.newpassWord();
      notifSystem.success(
        'Opération réussie',
        'Un SMS avec un code à 4 chiffres vous a été envoyé'
      );
    } catch (e) {
      notifSystem.error(e.name, e.response ?
        e.response.data : 'Numéro incorrect');
    }
  };

  changePassword = async () => {
    const { newPassword, verifnewPassword, tel, codeVerif } = this.state;
    if (newPassword.length < 6 || verifnewPassword !== newPassword) {
      return verifnewPassword !== newPassword ? (
        notifSystem.error('Erreur', 'Password different')
      ) : (
        <div style={{ margin: 25 }}>
          {notifSystem.error(
            'Le mot de passe doit etre',
            '6 caractères minimum'
          )}
        </div>
      );
    }
    try {
      const result = await api.login
        .custom('changePassword')
        .post({ tel, newPassword, codeVerif });
      if (result) {
        this.login();
        notifSystem.success(
          'Opération réussie',
          'Votre Mot de passe est changer'
        );
      }
    } catch (e) {
      notifSystem.error('Erreur', 'Numéro incorrect');
    }
  };

  getPassword = () => {
    this.setState((PrevState) => ({
      pass: '',
      forgetPassword: !PrevState.forgetPassword,
    }));
  };

  newpassWord = () => {
    this.setState((PrevState) => ({
      pass: '',
      changePassword: !PrevState.changePassword,
    }));
  };

  validate = () => {
    this.login().catch(() =>
      notifSystem.error('Erreur', 'Identifiants incorrect')
    );
  };

  render() {
    const {
      tel,
      pass,
      open,
      forgetPassword,
      changePassword,
      verifnewPassword,
      newPassword,
      codeVerif,
    } = this.state;
    return <React.Fragment>
      {changePassword && (
        <ChangePassword
          newPassword={newPassword}
          goBack={this.newpassWord}
          open={open}
          verifnewPassword={verifnewPassword}
          codeVerif={codeVerif}
          changePassword={this.changePassword}
          handleChange={this.handleChange}
        />
      )}
      {!forgetPassword ? (
        <LoginDialog
          open={open}
          tel={tel}
          pass={pass}
          validate={this.validate}
          getPassword={this.getPassword}
          handleChange={this.handleChange}
        />
      ) : (
        <ForgetPassword
          open={open}
          tel={tel}
          forgetPassword={this.forgetPassword}
          getPassword={this.getPassword}
          handleChange={this.handleChange}
        />
      )}
    </React.Fragment>;
  }
}
export default compose(
  withRouter,
  connect(mapStateToProps),
)(LoginId);
