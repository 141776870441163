import './index.css';

import React from 'react';
import './utils/prototypes';
import Dashboard from './views/Dashboard';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import jwt from 'jsonwebtoken';
import { logout } from './actions/auth';
import company from '@lba-dev/package.local-globals/company';
import ws from './ws';
import theme from './theme';
import store from './store';
import api from './api';
import { initServices } from './services';

import ErrorBoundary from './utils/errorBoundary';
import { addUserSession } from './services/datadog';


let token;
let timeout;
const loader = document.querySelector('.loader');

// if you want to show the loader when React loads data again
const hideLoader = () => loader.classList.add('loader--hide');

const onTokenChange = () => {
  const state = store.getState();
  if (state.auth.token !== token) {
    token = state.auth.token;
    api.header('Authorization', `Bearer ${token}`);
    if (token === undefined) {
      ws.logout();
      return;
    }
    const payload = jwt.decode(token);
    if (payload && payload.exp && payload.exp > Date.now() / 1000) {
      addUserSession(payload);
      clearTimeout(timeout);
      ws.login({ token, payload });
      window.localStorage.setItem('token', token);
      timeout = setTimeout(
        () => store.dispatch(logout()),
        (payload.exp * 1000) - Date.now()
      );
    } else {
      store.dispatch(logout());
    }
  }
};

store.subscribe(onTokenChange);
initServices();
onTokenChange();
document.title = company.name;

const App = () => (
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={'/'}>
          <ErrorBoundary>
            <Dashboard hideLoader={hideLoader} />
          </ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);

export default App;
