import React from 'react';
import CustomDialog from '../Dialogs/CustomDialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const ForgetPassword = ({ open, forgetPassword, getPassword,
  tel, handleChange }) =>
  <CustomDialog
    open={open}
    disableBackdropClick
    disableEscapeKeyDown
    name="Mot de passe oublié"
    subTitle={'Merci d\'entrer votre numéro de téléphone'}
    actions={[
      <Button key={0} onClick={forgetPassword}
        style={{ fontSize: 'large' }} color="primary">
        Valider
      </Button>,
      <Button key={1} onClick={getPassword} color="primary">
        Retour
      </Button>
    ]}
    actionStyle={{
      justifyContent: 'center',
      flexDirection: 'column'
    }}
  >
    <TextField
      onChange={handleChange('tel')}
      autoFocus
      margin="dense"
      id="tel"
      label={'Numéro de téléphone'}
      type="text"
      value={tel}
      inputProps={{
        style: { textAlign: 'center' }
      }}
      required
      fullWidth
    />
  </CustomDialog>;

export default ForgetPassword;
