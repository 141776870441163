import React from 'react';
import { withStyles } from 'tss-react/mui';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import { blue } from '@mui/material/colors';
import api from '../../api';
import notifSystem from '../../notifSystem';
import { connect } from 'react-redux';
import { moment } from '@lba-dev/package.local-globals/moment';
import { compose } from 'redux';
import withRouter from './withRouter';

const styles = theme => ({
  card: {
    width: '100%',
  },
  root: {
    flexGrow: 1,
  },
  actions: {
    display: 'flex',
    width: '100%',
  },
  papper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  button: {
    textDecoration: 'none',
    color: blue[500],
    width: '100%',
  },
});

const mapStateToProps = state => ({
  user: state.auth.decodedToken,
});

export default WrappedComponent => compose(
  connect(mapStateToProps),
  withRouter
)(withStyles(
  class AgentTabs extends React.Component {
    state = {
      groups: [],
      value:
        window.location.pathname === '/'
          ? '/services'
          : window.location.pathname,
      startAt: new Date(),
      endAt: new Date(),
    };

    handleChange = (event, value) => {
      const { navigate } = this.props;
      this.setState({ value }, () => navigate(value));
    };

    handleChangeDate = (value, label) => {
      this.setState(
        {
          [label]: moment(value).toDate(),
        },
        () => this.getEquipe()
      );
    };

    componentDidMount() {
      this.getEquipe();
    }

    getEquipe = () => {
      const { user } = this.props;
      api.equipes
        .getAll({
          ids: JSON.stringify(user.servicesIds),
        })
        .then(e => {
          const groups = e.body().map(e => e.data());
          this.setState({ groups });
        })
        .catch(() =>
          notifSystem.error('Erreur', 'Erreur lors du chargement des groupes')
        );
    };

    render() {
      const { classes, user } = this.props;
      const { groups, value } = this.state;
      return (
        <Grid
          container
          className={classes.root}
          spacing={16}
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Tabs
              value={value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              {user.pages.map(
                (e, i) =>
                  e.enable && <Tab
                    key={i}
                    label={e.route.slice(1)}
                    value={e.route}
                  />
              )}
            </Tabs>
          </Grid>
          {groups && (
            <WrappedComponent
              handleChangeDate={this.handleChangeDate}
              {...this.state}
            />
          )}
        </Grid>
      );
    }
  }, styles));
