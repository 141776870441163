import React, { PureComponent } from 'react';
import Notification from './Notification';
import notifSystem from '../../notifSystem';

class Notifications extends PureComponent {
  constructor() {
    super();
    this.waitNotif = false;
    this.state = {
      notifs: []
    };
    this.incomingNotif = this.incomingNotif.bind(this);
    this.readNotif = this.readNotif.bind(this);
    this.removeNotif = this.removeNotif.bind(this);
  }
  componentDidMount() {
    this.unsubscribe = notifSystem.subscribe(this.incomingNotif);
    this.readNotif();
  }
  componentWillUnmount() {
    this.unsubscribe();
  }

  incomingNotif() {
    if (this.waitNotif) {
      this.readNotif();
    }
  }

  removeNotif(id) {
    this.setState((prevState) => ({
      notifs: prevState.notifs.filter(e => e.id !== id)
    }), this.readNotif);
  }

  readNotif() {
    this.waitNotif = false;
    this.setState(prevState => {
      let notifs = prevState.notifs;
      let hasNotif = true;
      while (notifs.length < 5 && hasNotif) {
        const notif = notifSystem.consume();
        if (notif !== undefined) {
          notifs = [...notifs, notif];
        } else {
          hasNotif = false;
        }
      }
      if (hasNotif === false) {
        this.waitNotif = true;
      }
      return { notifs };
    });
  }

  render() {
    return (
      <div style={{
        position: 'fixed',
        bottom: 32,
        left: 32,
        zIndex: 1301,
        pointerEvents: 'none',
        width: '85%'
      }}>
        {this.state.notifs.map(e => (
          <Notification message={e} key={e.id} removeNotif={this.removeNotif}/>
        ))}
      </div>
    );
  }
}

export default Notifications;
