import React from 'react';
import { connect } from 'react-redux';

import { closeDialog } from '../actions/dialog';
import * as dialogs from '../components/Dialogs';
import MinCustomDialog from '../components/Dialogs/MinCustomDialog';
import Button from '@mui/material/Button';

const mapStateToProps = ({ dialog }) => dialog;

const mapDispatchToProps = { closeDialog };

const mergeProps = (stateProps, dispatchProps) =>
  ({
    ...stateProps,
    ...dispatchProps,
    actions: stateProps.hideClose
      ? stateProps.actions
      : [
        {
          children: 'Fermer',
          color: 'secondary',
          onClick: dispatchProps.closeDialog,
        },
        ...stateProps.actions,
      ],
  });

const DialogManager = ({
  name,
  open,
  actions,
  dialogProps,
  contentProps,
  closeDialog
}) => {
  const DialogContent = name === 'emptyOnPurpose'
    ? () => <div />
    : dialogs[name];
  return DialogContent ? (
    <MinCustomDialog
      {...dialogProps}
      actions={actions.map(({ onClick, ...p }, i) => (
        <Button
          key={i}
          color="primary"
          {...p}
          onClick={() => onClick(contentProps, closeDialog)}
        />
      ))}
      open={open}
      onClose={(e, reason) => {
        if (dialogProps.disableBackdropClick && reason === 'backdropClick') {
          return;
        }
        return closeDialog(e, reason);
      }}
      children={<DialogContent />}
    />
  ) : null;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(DialogManager);
