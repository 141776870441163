import React, { PureComponent } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';

const styles = {
  footerButtons: {
    position: 'sticky',
    bottom: 0,
    background: 'white',
  }
};

class Footer extends PureComponent {
  render() {
    const { close, valid, classes } = this.props;
    return (
      <DialogActions className={classes.footerButtons}>
        <Button onClick={close}>
            Annuler
        </Button>
        <Button onClick={valid} color="primary">
            Valider
        </Button>
      </DialogActions>
    );
  }
}

export default withStyles(Footer, styles);
