import {
  LOGIN,
  LOGOUT
} from '../constants/ActionTypes';
import { stopSessionReplayRecording } from '../services/datadog';

export function login(token) {
  return {
    type: LOGIN,
    token,
    decodedToken: JSON.parse(
      decodeURIComponent(escape(window.atob(token
        .split('.')[1]
        .replace(/-/g, '+')
        .replace(/_/g, '/'))))
    )
  };
}

export function logout() {
  window.localStorage.removeItem('token');
  stopSessionReplayRecording();
  return {
    type: LOGOUT
  };
}
