import React, { Component } from 'react';
import { login, logout } from '../actions/auth';

import api from '../api';
import notifSystem from '../notifSystem';
import store from '../store';
import { getArtisanInterventions } from '../actions/proposition';

const getUrl = (location) => location.pathname !== '/login'
  ? location.pathname
  : '';

class TokenManager extends Component {
  state = {
    url: getUrl(this.props.location),
    query: new URLSearchParams(this.props.location.search),
  };

  /**
   * Redirect actions + login through redux
   *
   * @param {Object} inter - inter data = getInter's return
   * @param {String} os - inter id number taken from query
   * @param {Object} data - token data = verifToken response
   */
  sendInterAction = ({ inter, os, tokenData, navigate, location }) => [
    store.dispatch(login(tokenData)),
    navigate(
      `${this.state.url}?${location.search.split('&').slice(1)}` || '/',
      {
        state: {
          data: inter,
          isUnique: true,
          ...(this.state.url
            ? {
              redirectPage: `${this.state.url}${os ? `?OS=${os}` : ''}` || '/',
            }
            : {}),
        }
      }
    ),
  ];

  /**
   * request to check if token is valid
   *
   * @param {String} token - token number taken from query
   */
  getTokenRequest = (token) => api.login.custom('verifToken').post({ token });

  /**
   * request to get interventions data with os id
   *
   * @param {Object} data - token data = verifToken response
   * @param {String} os - inter id number taken from query
   */
  getInter = (id, artisanId) =>
    getArtisanInterventions(artisanId, {
      query: JSON.stringify({ id }),
    }).then(({ data }) => data[0]);

  componentDidMount() {
    this.getToken();
  }

  getToken = async () => {
    try {
      const { location, navigate } = this.props;
      const { query, url } = this.state;
      const id = query.get('OS');
      const token = query.get('token');
      if (token) {
        const data = (await this.getTokenRequest(token))
          .body()
          .data();
        store.dispatch(login(data.token));
        if (id) {
          api.header('Authorization', `Bearer ${data.token}`);
          const inter = await this.getInter(id, data.payload.id);
          return this.sendInterAction({
            inter,
            os: id,
            tokenData: data.token,
            navigate,
            location,
          });
        }
        return navigate(url || '/', {
          state: {
            isUnique: true,
            ...(url
              ? { redirectPage: `${url}${id ? `?OS=${id}` : ''}` || '/' }
              : {}),
          }
        });
      }
    } catch (e) {
      store.dispatch(logout());
      notifSystem.error(
        'Erreur',
        e.response ? e.response.data : 'Echec connexion'
      );
    }
  };

  render() {
    return <></>;
  }
}


export default TokenManager;
