// Auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// Users
export const USERS_FETCH = 'USERS_FETCH';
export const USER_ID_FETCH = 'USER_ID_FETCH';

// Combos
export const COMBOS_FETCH = 'COMBOS_FETCH';
export const COMBOS_UPDATE = 'COMBOS_UPDATE';

// Combinations
export const COMBINATIONS_FETCH = 'COMBINATIONS_FETCH';
export const COMBINATIONS_UPDATE = 'COMBINATIONS_UPDATE';

// grandCompte
export const GRAND_COMPTE_FETCH = 'GRAND_COMPTE_FETCH';
export const GRAND_COMPTE_UPDATE = 'GRAND_COMPTE_UPDATE';

// Products
export const PRODUCTS_FETCH = 'PRODUCTS_FETCH';
export const PRODUCTS_UPDATE = 'PRODUCTS_UPDATE';

// Counter
export const COUNTER_FETCH = 'COUNTER_FETCH';

// Equipes
export const EQUIPES_FETCH = 'EQUIPES_FETCH';

// Notifications
export const NOTIFICATIONS_FETCH = 'NOTIFICATIONS_FETCH';
export const NOTIFICATIONS_UPDATE = 'NOTIFICATIONS_UPDATE';

// Market
export const MARKET_FETCH = 'MARKET_FETCH';
export const MARKET_UPDATE = 'MARKET_UPDATE';

// Annulations
export const ANNULATIONS_FETCH = 'ANNULATIONS_FETCH';
export const ANNULATIONS_UPDATE = 'ANNULATIONS_UPDATE';

// Dialog
export const SET_DIALOG = 'SET_DIALOG';
export const SET_DIALOG_CONTENT_PROP = 'SET_DIALOG_CONTENT_PROP';
export const SET_DIALOG_PROP = 'SET_DIALOG_PROP';


// Loading
export const LOADING = 'LOADING';
