import React from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';

import { connect } from 'react-redux';

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    background: 'rgba(255,255,255,0.75)',
    zIndex: 1301,
    display: 'flex',
  },
  title: {
    margin: theme.spacing(2),
  }
}));

const mapStateToProps = (state) => ({
  loading: state.loading
});

const LoadingManager = ({ loading }) => {
  const { classes } = useStyles();
  return (
    loading.open ?
      <Backdrop className={classes.backdrop} open>
        <CircularProgress size={50} color="secondary" />
        <h3 className={classes.title}
          children={loading.message || 'Chargement en cours...'}
        />
      </Backdrop>
      : <></>
  );
};

export default connect(mapStateToProps)(LoadingManager);
