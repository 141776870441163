import { combineReducers } from 'redux';

/* Reducers */
import auth from './auth';
import dialog from './dialog';
import loading from './loading';

const rootReducer = combineReducers({
  auth,
  dialog,
  loading
});

export default rootReducer;
