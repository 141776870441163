import { datadogRum } from '@datadog/browser-rum';

const version = process.env.PACKAGE_VERSION;

export const start = () =>
  datadogRum.init({
    applicationId: '5300d302-6ac1-4586-b1c5-98545270c5bd',
    clientToken: 'pub79453bd7b0fde80571d859dc36184029',
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
    env: process.env.NODE_ENV || 'develop',
    service: 'BackArtisanFront',
    version: `v${version}` || 'v1.0.0',
    defaultPrivacyLevel: 'allow',
  });

export const addUserSession = (user) => {
  datadogRum.setUser({
    id: `${user.id}`,
    name: user.companyName || user.login,
    tel1: user.tel1,
    _id: user._id,
  });
  datadogRum.startSessionReplayRecording();
};

export const stopSessionReplayRecording = () =>
  datadogRum.stopSessionReplayRecording();
