import React, { PureComponent } from 'react';
import Footer from './Footer';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

import { pourcent } from '@lba-dev/package.local-globals/AdvPaymentConstants';

const styles = {
  TextInput: {
    overflow: 'auto',
    maxHeight: 430,
    minHeight: 200,
    height: 435
  }
};

class Content extends PureComponent {
  render() {
    const {
      advancePayment,
      setPourcent,
      setMail,
      onClose,
      onValid
    } = this.props;
    return (
      <Card style={{ overflow: 'auto' }}>
        <CardContent>
          <div>
            <Typography variant="body2">Appliquer un acompte de</Typography>
            <Select
              value={advancePayment && advancePayment.get('pourcent', 0)}
              onChange={setPourcent}>
              {pourcent.map((value, index) =>
                <MenuItem key={index} value={index}>{value}%</MenuItem>
              )}
            </Select>
          </div>
        </CardContent>
        {advancePayment && advancePayment.get('pourcent', 0) !== 0 &&
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="textarea"
                multiline
                value={advancePayment.get('mail', '').replace(/<br>/g, '\n')}
                onContextMenu={(e) => {
                  e.stopPropagation();
                }}
                InputProps={{
                  style: styles.TextInput
                }}
                onChange={e => setMail(e.target.value)}
                label="Corps du mail"
              />
            </Grid>
          </Grid>
        </CardContent>
        }
        <Footer key="footer"
          close={onClose} valid={onValid} />
      </Card>
    );
  }
}

export default Content;
