import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';

const mapStateToProps = ({
  dialog: {
    contentProps: {
      message, location, callback
    },
  },
}) => ({
  message,
  callback,
  location
});

const InformPopUp = ({ message, timeout = 2000, location = '/', callback }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      callback();
      navigate(location);
    }, timeout);
  }, []);

  return (
    <Typography
      variant="subheading"
      children={message}
      align="center"
    />
  );
};

export default connect(mapStateToProps)(InformPopUp);
