import { LOADING } from '../constants/ActionTypes';

const initialState = {
  open: false,
  message: 'Chargement en cours...'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        message: action.message || initialState.message,
        open: action.open || initialState.open
      };
    default:
      return state;
  }
};
