import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../actions/dialog';
import CustomSelect from '../CustomInputs/CustomSelect';
import {
  declines
} from '@lba-dev/package.local-globals/reasons';
import { Grid, MenuItem } from '@mui/material';
import CustomTextField from '../CustomInputs/CustomTextField';
import DatePicker from '../DatePicker';
import { moment } from '@lba-dev/package.local-globals/moment';
import CustomRadioGroup from '../CustomInputs/CustomRadioGroup';
import TimePicker from '../TimePicker';
import { getTime, roundedTime } from '../../utils/date';

const setDeclineReason = setDialogContentProp('declineReason');
const setDeclineMessage = setDialogContentProp('declineMessage');
const setNewDispo = setDialogContentProp('newDispo');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      declineReason,
      declineMessage,
      newDispo,
    },
  },
}) => ({
  declineReason,
  declineMessage,
  newDispo,
});

const mapDispatchToProps = {
  setDeclineReason,
  setDeclineMessage,
  setNewDispo
};

const DeclineReasons = ({
  declineReason,
  setDeclineReason,
  setDeclineMessage,
  declineMessage,
  newDispo,
  setNewDispo
}) => {
  const [newDispoDate, setNewDispoDate] = useState(new Date());
  const [newDispoHours, setNewDispoHours] = useState(
    getTime(roundedTime(new Date(), 15))
  );

  const setMessage = () => {
    if (newDispoHours) {
      const numberPart = newDispoHours.split(':');
      newDispoDate.setHours(
        Number.parseInt(numberPart[0], 10),
        Number.parseInt(numberPart[1], 10),
        0,
        0
      );
    }
    if (newDispo) {
      setDeclineMessage('Je serai disponible le ' +
      `${moment(newDispoDate).format('dddd DD MMMM[ à ]HH:mm')}`);
    } else {
      setDeclineMessage(null);
    }
  };

  useEffect(() => {
    setMessage(newDispoDate);
  }, [newDispoDate, newDispoHours, newDispo]);

  return (
    <Grid
      container
      spacing={2}
      xs={12}
    >
      <CustomSelect
        grid
        xs={12}
        value={declineReason}
        path={'declineReason'}
        setData={(p, v) => setDeclineReason(v)}
      >
        {declines.map((e, i) => (
          <MenuItem key={i} value={e}>
            {e}
          </MenuItem>
        ))}
      </CustomSelect>
      {declineReason === 'Autres' ?
        <Grid item xs={12} style={{ alignSelf: 'center' }}>
          <CustomTextField
            fullWidth
            value={declineMessage}
            path={'declineMessage'}
            setData={(p, v) => setDeclineMessage(v)}
            placeholder="Raison"
            grid
            texttransform="unset"
          />
        </Grid> : ''}
      {declineReason === 'Indisponible' ?
        <Grid item container xs={12}>
          <Grid item container xs={12} spacing={3}
            justifyContent="center"
            padding={1}>
            <CustomRadioGroup
              grid
              xs={12}
              sm={6}
              md={12}
              label="Pourriez-vous réaliser l'intervention à un autre moment ?"
              value={newDispo?.toString()}
              values={[
                { label: 'Oui', value: '1' },
                { label: 'Non', value: '0' },
              ]}
              setData={(path, value) => setNewDispo(!!+value)}
            />
          </Grid>
          {newDispo &&
          <React.Fragment>
            <DatePicker
              label="Choisissez une date"
              startDate={newDispoDate}
              beforeDate={new Date()}
              onChange={v => setNewDispoDate(new Date(v))}
              style={{ width: '100%' }}
            />
            <TimePicker
              label="Choisissez une heure"
              name="date"
              value={newDispoHours}
              onChange={e => setNewDispoHours(e.target.value)}
              labelRemoveSpace={true}
              size="100%"
              start={'08:00'}
              end={'22:15'}
            />
          </React.Fragment>
          }
        </Grid> : ''}
    </Grid>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DeclineReasons);
