import React from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import {
  cancels
} from '@lba-dev/package.local-globals/reasons';
import { paths } from '../../../actions/proposition';
import CustomSelect from '../../CustomInputs/CustomSelect';
import CustomTextField from '../../CustomInputs/CustomTextField';
import withMediaQuery from '../../Hoc/WithMediaQuery';

const styles = {
  marginTop: {
    marginTop: 16,
  },
};

const updateTowRow = ({ setData, v, p }) => {
  setData('open', v === 'Autre');
  setData(p, v === 'Autre' ? '' : v);
};

const CancelStep = (props) => {
  const { merge, setData, classes, title } = props;
  const proposition = merge.get('proposition', new Map());
  return (
    !title && (
      <Grid container spacing={2} justifyContent="center">
        <Grid
          container
          item
          spacing={2}
          xs={12}
          justifyContent="space-around"
          className={classes.marginTop}
        >
          <CustomSelect
            grid
            xs={12}
            value={
              merge.get('open') ? 'Autre' : proposition.get('cancelRaison')
            }
            path={paths.cancelRaison}
            setData={(p, v) => updateTowRow({ setData, p, v })}
          >
            {cancels.map((e, i) => (
              <MenuItem key={i} value={e}>
                {e}
              </MenuItem>
            ))}
          </CustomSelect>
          <Grid item xs={12} sm={12}>
            <CustomTextField
              fullWidth
              value={
                merge.get('open')
                  ? proposition.get('cancelRaison')
                  : proposition.get('propComments')
              }
              path={merge.get('open') ? paths.cancelRaison : paths.propComments}
              setData={setData}
              label="Commentaire"
              stylesInputs={{ fontSize: 13 }}
              grid
            />
          </Grid>
        </Grid>
      </Grid>
    )
  );
};

export default withMediaQuery()(withStyles(CancelStep, styles));
