import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from 'tss-react/mui';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import withMediaQuery from '../Hoc/WithMediaQuery';
const styles = theme => ({
  content: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    margin: theme.spacing(1),
  },
  dialogtitle: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  }
});

const MinCustomDialog = ({
  title,
  children,
  actions,
  middle,
  classes: { content, button, dialogtitle, ...anotherClasse },
  returnButton,
  isDown,
  fullScreenOn,
  statusButton,
  dividers = 'false',
  ...props
}) => (
  <Dialog
    classes={anotherClasse}
    fullScreen={!!fullScreenOn && isDown[fullScreenOn]}
    {...props}
  >
    {title ? (
      <DialogTitle className={dialogtitle}>
        <div>
          {returnButton && returnButton.visibility ? (
            <IconButton className={button}>
              <KeyboardBackspace onClick={() => returnButton.func()} />
            </IconButton>
          ) : (
            ''
          )}
          <Typography variant="h6" align="center">
            {title}
          </Typography>
        </div>
        {statusButton && statusButton.name ? (
          <Button
            color={statusButton.color}
            style={{
              color: statusButton.customColor || null,
              borderColor: statusButton.customColor || null,
            }}
            {...statusButton.props}
          >
            {statusButton.name}
          </Button>
        ) : (
          ''
        )}
      </DialogTitle>
    ) : (
      ''
    )}
    {Array.isArray(children) ? (
      children.map((e, i) => (
        <DialogContent
          key={i}
          className={middle ? content : ''}
          children={e}
          dividers={dividers}
        />
      ))
    ) : (
      <DialogContent
        className={middle ? content : ''}
        children={children}
        dividers={dividers}
      />
    )}
    <DialogActions children={actions} />
  </Dialog>
);

MinCustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  children: PropTypes.element,
  actions: PropTypes.array.isRequired,
  middle: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  fullScreenOn: PropTypes.string
};

MinCustomDialog.defaultProps = {
  open: false,
  maxWidth: 'sm',
  fullScreenOn: 'sm',
  title: '',
  children: <div>Pas de contenu</div>,
  actions: [],
  middle: false,
  fullWidth: true,
  onClose: f => f
};

export default compose(
  withMediaQuery(),
)(withStyles(MinCustomDialog, styles));
