import company from '@lba-dev/package.local-globals/company';

export const openWhatsApp = (text = '') =>
  window.open(
    `https://wa.me/${
      process.env.NODE_ENV === 'production'
        ? company.whatsapp.prod
        : company.whatsapp.stage
    }/?text=${text}`,
  );
