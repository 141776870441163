import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';


const CustomRadioGroup = (props) => {
  const { xs, sm, md, lg, xl, label = '', value, labelStyle,
    values, grid, classGrid, classes = {}, flexDirection,
    justifyContent = 'space-between', fullWidth = true, gridProps = {},
  } = props;
  const input = (
    <FormControl
      component="fieldset"
      style={fullWidth ? { width: '100%' } : null}
      className={classes.formControl}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        className={classes.radioGroup}
        style={{ flexDirection, justifyContent, width: '100%' }}
        value={value}
        onChange={e => props.setData(props.path, e.target.value)}
      >
        {values && values.map((e, i) =>
          [<FormControlLabel key={i} style={labelStyle || null}
            classes={{ root: classes.controlLabel, label: classes.labelRadio }}
            value={e.value || i.toString()}
            control={<Radio />} label={e.label} />,
          e.desc ?
            <FormHelperText key={`${i}.1`}
              classes={{ filled: classes.radioDesc }} filled>
              {e.desc}
            </FormHelperText> : '']
        )}
      </RadioGroup>
    </FormControl>
  );
  if (grid) {
    return (
      <Grid
        item
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        className={classGrid}
        {...gridProps}
      >
        {input}
      </Grid>
    );
  }
  return input;
};


CustomRadioGroup.defaultProps = {
  flexDirection: 'row'
};

CustomRadioGroup.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string),
  setData: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  grid: PropTypes.bool,
  classGrid: PropTypes.string,
  flexDirection: PropTypes.string,
};

export default CustomRadioGroup;
