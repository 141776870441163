import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import {
  CircularProgress,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  Link,
} from '@mui/material';
import PayButton from '../Proposition/payButton';
import {
  getOneProposition,
  paths,
} from '../../actions/proposition';
import ws from '../../ws';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import HighlightOff from '@mui/icons-material/HighlightOff';
import {
  T_REGLE,
  T_TOSEND,
  T_FAILED
} from '@lba-dev/package.local-globals/transactionStatus';
import { green, red } from '@mui/material/colors';

const mapStateToProps = ({
  dialog: {
    contentProps: {
      setData,
      artisanId,
      mail,
      tel,
      montant,
      id,
      to,
      merge
    },
  }
}) => ({
  setData,
  artisanId,
  mail,
  tel,
  montant,
  id,
  to,
  merge
});

const steps = (activeStep) => [
  {
    title: 'Lien de paiement',
    content:
      activeStep === 1
        ? 'Le lien de paiement a été envoyé avec ' +
        'succès au client ainsi qu\'à vous-même.'
        : 'Le client et vous-même allez ' +
          'recevoir un lien de paiement en ligne',
    loaderText: '',
  },
  {
    title: 'Paiement du client',
    content: 'Le client ouvre le lien et paie en ligne le montant indiqué',
    loaderText: 'En attente du paiement du client',
  },
  {
    title: 'Paiement reçu',
    content: 'Le paiement a bien été réceptionné, ' +
      'la facture acquittée est transmise directement par mail au client',
    loaderText: '',
  },
];

const GetIcon = {
  [T_REGLE]: {
    icon: <CheckCircleOutline style={{ color: green[500], fontSize: 70 }}/>,
    text: 'Le paiement a été accepté'
  },
  [T_FAILED]: {
    icon: <HighlightOff style={{ color: red[500], fontSize: 70 }}/>,
    text: 'Le paiement a été refusé'
  }
};

const styles = {
  stepText: {
    dominantBaseline: 'middle'
  }
};

class Paiement extends Component {
  constructor(props) {
    super(props);
    const { merge } = props;
    const transactionStatus = merge.getIn(paths.transactionStatus);
    this.state = {
      newDatePaiement: null,
      activeStep: ([T_REGLE, T_FAILED].includes(transactionStatus) && 4) ||
        (this.props.merge.getIn(paths.demandePayment) ? 1 : -1),
      status: transactionStatus || T_TOSEND,
      isClicked: false,
      transactionUrl: merge.get('transactionUrl'),
    };
  }

  getProposition = async (id) => {
    const { merge, setData } = this.props;
    if (id === merge.get('id')) {
      const interUpdated = await getOneProposition(id);
      const newTransacStatus = interUpdated.transactionStatus;
      this.setState({
        ...[T_REGLE, T_FAILED].includes(newTransacStatus) && {
          activeStep: 4,
          status: newTransacStatus,
          transactionUrl: interUpdated.transactionUrl,
          isClicked: false
        },
        ...newTransacStatus === T_TOSEND && {
          activeStep: -1,
          status: newTransacStatus,
          transactionUrl: interUpdated.transactionUrl,
          isClicked: false
        },
      }, () => {
        setData(paths.transactionStatus, newTransacStatus);
        setData(paths.transactionUrl, interUpdated.transactionUrl);
        setData(paths.isRegle, interUpdated.isRegle);
      });
    }
  }

  componentDidMount() {
    ws.on('update_propositions', (update) => {
      this.getProposition(update);
    });
  }

  updateState = (newState = {}) => this.setState({ ...newState })

  render() {
    const { activeStep, status, isClicked, transactionUrl } =
      this.state;
    const {
      setData,
      merge,
      mail,
      tel,
      montant,
      artisanId,
      to,
      classes
    } = this.props;
    const iconByStatus = GetIcon[status];
    return (
      <Grid container spacing={2} xs={12}>
        <Grid item xs={12}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps(activeStep).map((step, index) => (
              <Step key={index}>
                <StepLabel
                  StepIconProps={{
                    classes: { text: classes.stepText },
                  }}
                >
                  {step.title}
                  <br />
                  <Typography variant="caption">{step.content}</Typography>
                </StepLabel>
                <StepContent>
                  <Grid container textAlign="center">
                    <Grid item xs={12}>
                      <CircularProgress size={34} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography children={step.loaderText} color={red[500]} />
                    </Grid>
                    {transactionUrl && (
                      <Grid item xs={12}>
                        <Link target={'_blank'} href={transactionUrl}>
                          Ou cliquez ici
                        </Link>
                      </Grid>
                    )}
                  </Grid>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
        {[T_FAILED, T_REGLE].includes(status) && !isClicked ? (
          <Grid item xs={12} textAlign="center">
            {iconByStatus.icon}
            <Typography>{iconByStatus.text}</Typography>
          </Grid>
        ) : (
          ''
        )}
        {status !== T_REGLE ? (
          <Grid item xs={12}>
            <PayButton
              inter={merge}
              withAdvance={true}
              setData={(path, value) => setData(path, value)}
              updateState={this.updateState}
              merge={merge}
              mail={mail}
              tel={tel}
              montant={montant}
              artisanId={artisanId}
              to={to}
              activeStep={activeStep}
            />
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    );
  }
}

export default connect(mapStateToProps)(withStyles(Paiement, styles));
