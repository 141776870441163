import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

const getDefaultValue = (defaultValue) =>
  defaultValue ? (defaultValue / 100).toString(10) : '0.00';

class MoneyInput extends PureComponent {
  constructor(props) {
    super();
    this.onChange = this.onChange.bind(this);
    this.state = {
      error: false,
      value: getDefaultValue(props.defaultValue),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.defaultValue !== nextProps.defaultValue) {
      this.setState({
        value: getDefaultValue(nextProps.defaultValue),
      });
    }
  }

  onChange(e) {
    const { setData, path, canBeNegative } = this.props;
    const value = e.target.value;
    const valid = (canBeNegative
      ? /^-?(\d+)(\.\d{1,2})?$/
      : /^(\d+)(\.\d{1,2})?$/
    ).test(e.target.value);
    this.setState(
      this.state.error === valid ? { error: !valid, value } : { value }
    );
    if (setData && valid) {
      if (this.props.elemPos >= 0) {
        setData(
          path,
          valid ? Math.round(Number.parseFloat(value * 100)) : NaN,
          this.props.elemPos
        );
      } else {
        setData(path, valid ? Math.round(Number.parseFloat(value * 100)) : NaN);
      }
    }
  }

  render() {
    const {
      xs,
      sm,
      md,
      lg,
      xl,
      grid,
      classGrid,
      requiredText,
      gridProps = {},
      ...props
    } = this.props;
    const { value, error } = this.state;
    delete props.defaultValue;
    delete props.setData;
    delete props.path;
    const input = (
      <TextField
        {...props}
        value={value}
        error={error}
        type="number"
        InputProps={{ inputProps: { step: '0.01', min: '0' } }}
        fullWidth
        onChange={this.onChange}
        required={requiredText}
      />
    );
    if (grid) {
      return (
        <Grid
          item
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
          className={classGrid}
          {...gridProps}
        >
          {input}
        </Grid>
      );
    }
    return input;
  }
}

MoneyInput.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  setData: PropTypes.func.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.number,
  classGrid: PropTypes.string,
};

export default MoneyInput;
