import openSocket from 'socket.io-client';
import notifSystem from './notifSystem';

class Ws {
  login(user) {
    this.socket = openSocket({
      transports: ['websocket'],
    });
    this.lock = new Promise((res, rej) => {
      this.socket.once('logged', res);
      this.socket.once('disconnect', rej);
    });
    this.socket.emit('login', user);
    this.socket.on('error', this.printError);
    this.socket.on('disconnect', (e) => this.disconnect(e, user));
    this.socket.on('notif', notifSystem.send);
    this.socket.on('connect', () => {
      this.connect(user);
    });
  }

  printError(err) {
    notifSystem.error(err.name, err.message);
  }

  connect(user) {
    setTimeout(() => {
      if (this.socket) {
        this.socket.emit('login', user);
      }
    }, 5000);
  }

  disconnect(e, user) {
    setTimeout(() => {
      if (e === 'io server disconnect') {
        this.login(user);
      } else if (!this.socket) {
        this.disconnect(e, user);
      } else {
        this.socket.connect();
      }
    }, 5000);
  }

  logout() {
    if (this.socket) {
      this.socket.removeAllListeners();
      this.socket.close();
      this.socket = undefined;
    }
  }

  async send(...args) {
    await this.lock;
    if (this.socket) {
      this.socket.emit(...args);
    }
  }

  on(...args) {
    return this.socket.on(...args);
  }

  once(...args) {
    return this.socket.once(...args);
  }

  removeEventListener(...args) {
    if (this.socket) {
      return this.socket.removeListener(...args);
    }
  }
}

export default new Ws();
