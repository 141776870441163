import React from 'react';
import componentLoader from '../../componentLoader';


export { default as AgentTabs } from './../../components/Hoc/AgentTabs';

export const InterventionService = React.lazy(() =>
  componentLoader(() =>
    import('./../../components/Agents/InterventionService')
  )
);
export const MapCalls = React.lazy(() =>
  componentLoader(() => import('./../../components/Agents/MapCalls'))
);
export const StatsServices = React.lazy(() =>
  componentLoader(() => import('./../../components/Agents/StatsServices'))
);

