import categories from '@lba-dev/package.local-globals/categories';
import company from '@lba-dev/package.local-globals/company';
import { R_FOR, R_POR } from '@lba-dev/package.local-globals/remStatus';
import {
  S_INT,
  S_DEVIS,
  S_LEAD,
} from '@lba-dev/package.local-globals/services';
import { moment } from '@lba-dev/package.local-globals/moment';
import { moneyToString } from '@lba-dev/package.local-globals/formatPrice';
import Money from '../utils/Money/Money';

const addressToString = (address) => {
  const roadNumber = address.number;
  const road = address.road;
  const zipcode = address.zipcode;
  const city = address.city;
  return `${roadNumber} ${road}, ${zipcode} ${city}`;
};
const complementAdress = (client) => {
  const compl = client.compl ? `Complément d'adresse :\n${client.compl}` : '';
  const floor = client.floor ? `Etage : ${client.floor}, ` : '';
  const code = client.code ? `code : ${client.code}, ` : '';
  const building = client.building ? `bâtiment : ${client.building}` : '';
  let compl2 = floor || code || building ?
    `(${floor + code + building})\n` : '';
  return `${compl}\n${compl2}`;
};
const clientToString = (client) => {
  const civility = client.civility || '';
  const name = client.name || '';
  const firstname = client.firstname ? ` ${client.firstname}` : '';
  if (client.civility === 'Société') {
    return `${civility}. ${client.society || ''}\n(${name}${firstname})`;
  }
  return `${civility}. ${name}${firstname}`;
};

const dateToString = (date, flexHour, maxHour) => flexHour ?
  `le ${moment(date).format('DD/MM [entre] HH[h]mm')
  } et ${moment(maxHour).format('HH[h]mm')}` :
  `le ${moment(date).format('DD/MM [à] HH[h]mm')}`;

const paymentOnSite = (onSitePayment) => `${onSitePayment ?
  'Vous devez récupérer le règlement du client' :
  'Vous ne devez pas récupérer le règlement du client'}`;

function getPrice(inter, artisan) {
  if (artisan.remuneration && artisan.remuneration.status === R_FOR &&
    inter.priceArtisan) {
    return `${moneyToString(inter.priceArtisan)} H.T`;
  }
  if (inter.finalPrice) {
    return `${moneyToString(inter.finalPrice)} H.T`;
  }
  return `${inter.flexPrice ?
    `entre ${moneyToString(inter.announcedPrice ?
      (inter.announcedPrice).toFixed(2) : 0)
    }  et ${moneyToString(
      inter.announcedPriceMax ?
        (inter.announcedPriceMax).toFixed(2) : 0)} H.T \n` :
    `${moneyToString(inter.announcedPrice ? (inter.announcedPrice).toFixed(2) :
      0 )} H.T \n`}`;
}

const getDevisPrice = (devis) => {
  const sum = ((devis.finalPrice ||
    (devis.products && devis.products.list &&
    devis.products.list.reduce((p, c) => {
      const t = c.pu || 0;
      return p + (t * (c.qt || 1));
    }, 0))) / 100).toFixed(2);
  const tva = (devis.products && devis.products.tva) || 10;
  const tvaPrice = (sum * (tva / 100)).toFixed(2);
  const ttc = (sum * ((tva / 100) + 1)).toFixed(2);
  return ((sum && tva && ttc) ?
    `Montant H.T : ${sum}\n` +
    `TVA (${tva}%) : ${tvaPrice}\n` +
    `Montant TTC : ${ttc}\n` : '');
};
function getNumber(inter, artisan) {
  if (inter.smsAxialis && (!artisan.remuneration ||
    (artisan.remuneration.status === R_POR ||
    (artisan.remuneration.status === R_FOR && inter.onSitePayment)))) {
    return company.lines.smsAxialis;
  }
  return `${inter.client.tel1.match(/.{2}/g).join('.')}` +
    `${inter.client.tel2 ? ` ou\n ${inter.client.tel2.match(/.{2}/g).join('.')}` : ''}` +
    `${inter.client.tel3 ? ` ou\n ${inter.client.tel3.match(/.{2}/g).join('.')}` : ''}`;
}

export const generateSmsSAV = (inter, artisan, user) => {
  const date = new Date(inter.date.intervention);
  const maxHour = inter.infoDesc.maxHour ?
    new Date(inter.infoDesc.maxHour) :
    moment(date.getTime()).add(2, 'hours').toDate();
  const login = (user && user.alias) || '';
  const num = user && user.ligne.replace(/\+33/, '0').match(/.{2}/g).join('.');
  return (`SAV ${inter.id}\n` +
  `${dateToString(date, inter.flexHour, maxHour)}\n` +
  `${clientToString(inter.client)}\n` +
  `${addressToString(inter.client.address)}\n` +
  `${complementAdress(inter.client)}` +
  `Tel: ${getNumber(inter, artisan)} (OS ${inter.id})\n` +
  `${inter.infoDesc && inter.infoDesc.desc ?
    inter.infoDesc.desc : 'Description : Aucune'}\n` +
  `${inter.infoDesc && inter.infoDesc.context ?
    `Remarque : ${inter.infoDesc.context}` : ''}\n` +
  `${getPrice(inter, artisan)}\n` +
  `${login} : ${num}\n` +
  `Support : ${company.lines.telSupport}\n`);
};

export const generateSmsDevis = (devis, user) => {
  const login = (user && user.alias) || '';
  const num = user && user.ligne.replace(/\+33/, '0').match(/.{2}/g).join('.');

  return devis.client && (
    `${clientToString(devis.client)}\n\n` +
  'Voici un récapitulatif du devis :\n' +
    `Devis ${devis.id || '{id}'}\n\n` +
    '/lien/\n' +
    'Cliquez sur ce lien pour visualiser le devis.\n\n' +
    `${getDevisPrice(devis)}` +
    'Envoyez "oui" pour accepter ou \n "non" pour refuser.\n\n' +
    `${login} : ${num || ''}\n` +
    `Support : ${company.lines.telSupport}\n`);
};


export const generateSmsInter = (inter, user, os, artisan) => {
  const date = new Date(inter.date.intervention);
  const maxHour = inter.infoDesc.maxHour ?
    new Date(inter.infoDesc.maxHour) :
    moment(date.getTime()).add(2, 'hours').toDate();
  const client = inter.client;
  const address = client.address;
  const login = (user && user.alias) || '';
  const num = user && user.ligne.replace(/\+33/, '0').match(/.{2}/g).join('.');
  const support = user && [S_INT, S_DEVIS, S_LEAD].includes(user.service) ?
    user.ligneService.replace(/\+33/, '0').match(/.{2}/g).join('.')
    : company.lines.telSupport;
  const serviceNum = user && user.ligneService
    .replace(/\+33/, '0').match(/.{2}/g).join('.');
  const idInter = inter.id || '{id}';
  const categoriePlain = categories.data.find(
    e => e._id === inter.categorie).name;
  let tva = inter.client.civility === 'Société' ? 1.20 : 1.10;
  let ttc = ((inter.announcedPrice ?
    ((inter.announcedPrice / 100) * tva).toFixed(2) : 0));
  if (artisan.remuneration.status !== R_FOR && inter.flexPrice) {
    ttc = `entre ${(inter.announcedPrice ?
      ((inter.announcedPrice / 100) * tva).toFixed(2) : 0)} et ${(
      inter.announcedPriceMax ?
        ((inter.announcedPriceMax / 100) * tva).toFixed(2) : 0)}`;
  }
  if ((os === undefined || os === false) && artisan !== undefined) {
    return (
      `M.${artisan.name}\n` +
      `Etes-vous disponible pour une intervention de ${categoriePlain}\n` +
      `${dateToString(date)} à ` +
      `${address.city} ${address.zipcode} ? \n` +
      `${login}: ${num} ou ${serviceNum}\n`
    );
  } else if (os === true) {
    let message = (`OS ${idInter}\n` +
        `${dateToString(date, inter.flexHour, maxHour)}\n` +
        `${clientToString(client)}\n` +
        `${addressToString(address)}\n` +
        `${complementAdress(client)}` +
        `Tel client : ${getNumber(inter, artisan)} (OS ${idInter}#)\n` +
        `${inter.infoDesc && inter.infoDesc.desc ?
          inter.infoDesc.desc : 'Description : Aucune'}\n` +
        `${inter.infoDesc && inter.infoDesc.context ?
          `Remarque : ${inter.infoDesc.context}` : ''}\n` +
        `${paymentOnSite(inter.onSitePayment)} \n` +
        `${getPrice(inter, artisan)}\n` +
        `${login} : ${num}\n` +
        `Support : ${serviceNum}\n`);
    if (!inter.onSitePayment && artisan.remuneration.status === R_FOR)
    {
      message = (`OS ${idInter}\n` +
      `${dateToString(date, inter.flexHour, maxHour)}\n` +
      `${clientToString(client)}\n` +
      `${addressToString(address)}\n` +
      `${complementAdress(client)}` +
      `Tel client : ${getNumber(inter, artisan)} (OS ${idInter}#)\n` +
      `${inter.infoDesc && inter.infoDesc.desc
        ? inter.infoDesc.desc : 'Description : Aucune'}\n` +
      `${inter.infoDesc && inter.infoDesc.context ?
        `Remarque : ${inter.infoDesc.context}\n` : ''}` +
      `${inter.infoDesc && inter.infoDesc.consignes ?
        `Consignes : ${inter.infoDesc.consignes}\n` : ''}\n` +
      `Tarif de votre prestation : ${getPrice(inter, artisan)}\n` +
      `${login} : ${num}\n` +
      `Support : ${support}`);
    } else if (inter.onSitePayment && artisan.remuneration.status === R_FOR) {
      message = (`OS ${idInter}\n` +
      `${dateToString(date, inter.flexHour, maxHour)}\n` +
      `${clientToString(client)}\n` +
      `${addressToString(address)}\n` +
      `${complementAdress(client)}` +
      `Tel client : ${getNumber(inter, artisan)} (OS ${idInter}#)\n` +
      `${inter.infoDesc && inter.infoDesc.desc
        ? inter.infoDesc.desc : 'Description : Aucune'}\n` +
      `${inter.infoDesc && inter.infoDesc.context ?
        `Remarque : ${inter.infoDesc.context}\n` : ''}` +
      `${inter.infoDesc && inter.infoDesc.consignes ?
        `Consignes : ${inter.infoDesc.consignes}\n` : ''}\n` +
      `Vous devez récupérer le règlement du client : ${ttc} T.T.C\n\n` +
      `Tarif de votre prestation : ${getPrice(inter, artisan)}\n\n` +
      `${login} : ${num}\n` +
      `Support : ${support}`);
    } else if (!inter.onSitePayment && artisan.remuneration.status === R_POR) {
      message = (`OS ${idInter}\n` +
      `${dateToString(date, inter.flexHour, maxHour)}\n` +
      `${clientToString(client)}\n` +
      `${addressToString(address)}\n` +
      `${complementAdress(client)}` +
      `Tel client : ${getNumber(inter, artisan)} (OS ${idInter}#)\n` +
      `${inter.infoDesc && inter.infoDesc.desc
        ? inter.infoDesc.desc : 'Description : Aucune'}\n` +
      `${inter.infoDesc && inter.infoDesc.context ?
        `Remarque : ${inter.infoDesc.context}\n` : ''}` +
      `${inter.infoDesc && inter.infoDesc.consignes ?
        `Consignes : ${inter.infoDesc.consignes}\n` : ''}\n` +
      `Prix annoncé : ${getPrice(inter, artisan)}\n` +
      `${login} : ${num}\n` +
      `Support : ${support}`);
    } else if (inter.onSitePayment && artisan.remuneration.status === R_POR) {
      message = (`OS ${idInter}\n` +
      `${dateToString(date, inter.flexHour, maxHour)}\n` +
      `${clientToString(client)}\n` +
      `${addressToString(address)}\n` +
      `${complementAdress(client)}` +
      `Tel client : ${getNumber(inter, artisan)} (OS ${idInter}#)\n` +
      `${inter.infoDesc && inter.infoDesc.desc
        ? inter.infoDesc.desc : 'Description : Aucune'}\n` +
      `${inter.infoDesc && inter.infoDesc.context ?
        `Remarque : ${inter.infoDesc.context}\n` : ''}` +
      `${inter.infoDesc && inter.infoDesc.consignes ?
        `Consignes : ${inter.infoDesc.consignes}\n` : ''}\n` +
      'Vous devez récupérer le règlement du client.\n\n' +
      `Prix annoncé : ${getPrice(inter, artisan)}\n` +
      `${login} : ${num}\n` +
      `Support : ${support}`);
    }
    return message;
  }
};

export const assistanceMessage = (inter) => {
  const client = inter.client;
  const displayedAddress = client.displayedAddress.split(', ');
  return `${inter.user.alias}, je te contacte concernant l'intervention ` +
  `${inter.id} ` +
    `${client.partName} - ${displayedAddress
    } - ${Money.toString(inter.priceTtc, true)} - ${inter.infoDesc.desc}`;
};
