import React, { PureComponent } from 'react';

import Paper from '@mui/material/Paper';
import { withStyles } from 'tss-react/mui';
import NotifTransition from './NotifTransition';

const styles = {
  notif: {
    pointerEvents: 'auto',
    marginTop: 10,
    height: 0,
    width: '100%',
    display: 'flex',
    color: '#333',
    transformOrigin: 'bottom left'
  },
  notifIconSection: {
    backgroundColor: '#00000040',
    flex: '0 0 52px',
    position: 'relative'
  },
  icon: {
    fill: 'white',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  textSection: {
    fontSize: 14,
    lineHeight: 1.75,
    padding: 10,
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
};

class Notification extends PureComponent {
  constructor() {
    super();
    this.state = { open: true };
    this.removeComponent = this.removeComponent.bind(this);
    this.closeComponent = this.closeComponent.bind(this);
    this.onExit = this.onExit.bind(this);
  }

  componentDidMount() {
    if (!this.props.message.duration ||
        this.props.message.duration >= 0) {
      this.timeout = setTimeout(
        this.closeComponent,
        this.props.message.duration || 5000
      );
    }
  }

  closeComponent() {
    clearTimeout(this.timeout);
    this.setState({ open: false });
  }

  removeComponent() {
    this.props.removeNotif(this.props.message.id);
  }

  onExit(duration) {
    this.exitTimeout = setTimeout(this.removeComponent, duration);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.exitTimeout);
  }

  render() {
    const {
      classes,
      message
    } = this.props;
    const style = { backgroundColor: message.type.color };
    return (
      <NotifTransition in={this.state.open} onExit={this.onExit}>
        <Paper
          elevation={5}
          style={style}
          className={classes.notif}
          onClick={this.closeComponent}
        >
          <div className={classes.notifIconSection} >
            <message.type.icon className={classes.icon} />
          </div>
          <div className={classes.textSection}>
            <div
              className={classes.text}
              style={{ fontWeight: 'bold' }}
            >{message.title}</div>
            <div className={classes.text}>{message.msg}</div>
          </div>
        </Paper>
      </NotifTransition>
    );
  }
}

export default withStyles(Notification, styles);
