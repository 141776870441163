import React from 'react';
import CustomDialog from '../Dialogs/CustomDialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
const ChangePassword = ({ open, changePassword, goBack,
  codeVerif, verifnewPassword, newPassword, handleChange }) =>
  <CustomDialog
    open={open}
    disableBackdropClick
    disableEscapeKeyDown
    name="Changement de mot de passe"
    subTitle="merci d'entrer votre nouveau mot de passe"
    actions={[
      <Button key={0} onClick={changePassword} color="primary">
        Valider
      </Button>,
      <Button key={1} onClick={goBack} color="primary">
        Retour
      </Button>
    ]}
    actionStyle={{
      justifyContent: 'center'
    }}
  >
    <TextField
      onChange={handleChange('codeVerif')}
      autoFocus
      margin="dense"
      id="codeVerif"
      label={'Code de Vérification'}
      type="text"
      value={codeVerif}
      inputProps={{
        style: { textAlign: 'center' }
      }}
      required
      fullWidth
    />
    <TextField
      onChange={handleChange('verifnewPassword')}
      autoFocus
      margin="dense"
      id="verifnewPassword"
      label={'Nouveau mot de passe'}
      type="password"
      value={verifnewPassword}
      inputProps={{
        style: { textAlign: 'center' }
      }}
      required
      fullWidth
    />
    <TextField
      onChange={handleChange('newPassword')}
      autoFocus
      margin="dense"
      id="newPassword"
      type="password"
      label={'Confirmer votre nouveau mot de passe'}
      value={newPassword}
      inputProps={{
        style: { textAlign: 'center' }
      }}
      required
      fullWidth
    />
  </CustomDialog>;

export default ChangePassword;
