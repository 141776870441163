import React from 'react';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';

const mapStateToProps = ({
  dialog: {
    contentProps: { message },
  },
}) => ({
  message,
});

const VerficationProp = ({ message }) => (
  <Typography
    variant="subheading"
    children={message}
    align="center"
  />
);

export default connect(mapStateToProps, VerficationProp)(VerficationProp);
