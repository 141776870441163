import React, { useState, useEffect, Suspense } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { compose } from 'redux';
import Page404 from './Page404';
import { connect } from 'react-redux';
import DialogManager from './DialogManager';
import TokenManager from './TokenManager';
import { logout } from '../actions/auth';
import {
  Navigate,
} from 'react-router';
import {
  useLocation,
  useNavigate,
  Route,
  Routes,
} from 'react-router-dom';
import Login from './Login';
import LoginId from '../components/Dashboard/LoginId';
import Notifications from '../components/Notifications';
import ActionNotification from '../components/Notifications/ActionNotification';
import Header from './Header.js';
import customTheme from '../config/customTheme';
import routers from '../config/router';
import {
  S_AGENT
} from '@lba-dev/package.local-globals/services';
import LoadingManager from './LoadingManager';
import withMediaQuery from '../components/Hoc/WithMediaQuery';


const defaultTheme = createTheme();
const theme = createTheme(customTheme(defaultTheme));

const mapStateToProps = (state) => ({
  auth: state.auth,
  pages: (state.auth.decodedToken || {}).pages || []
});

export const Dashboard = React.memo((props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { auth: { decodedToken } } = props;
  const [loggedOnce, setLoggedOnce] = useState(props.auth.token !== undefined);

  const scrollToRef = () => {
    if (!!navigator.userAgent &&
      (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i)
        .test(navigator.userAgent)) {
      setTimeout(() =>
        window.scrollTo(0, 45), 1000);
    }
  };

  useEffect(() => {
    scrollToRef();
    props.hideLoader();
    if (loggedOnce === false
      && props.auth.token
      && props.auth.token !== undefined
    ) {
      setLoggedOnce(true);
    }
    if (props.auth.token && Object.keys(props.auth).length === 0) {
      setLoggedOnce(false);
    }
  }, [props.auth]);

  const deconnexion = () => {
    if (((props.auth || {}).decodedToken || {}).service === S_AGENT) {
      navigate('/agents');
    } else {
      navigate('/login', {
        state: {
          from: location
        }
      });
    }
    props.dispatch(logout());
  };

  const url = location?.state?.redirectPage;
  const autoriseRoute = decodedToken && loggedOnce
    ? routers(props)
    : [];
  return (
    <div style={{ height: '100%' }}>
      <ThemeProvider theme={theme}>
        <Header
          user={decodedToken}
          deconnexion={deconnexion}
          navigate={navigate}
          pageName={location.pathname}
        />
        <TokenManager
          location={location}
          navigate={navigate}
          auth={props.auth}
        />
        <Routes>
          {!loggedOnce && url
            ? <Route path={`${url}` || '/'} element={
              <Navigate to={`${url}` || '/'}
                {...(location.state.redirectPage
                  ? { state: location.state }
                  : {})}
              />
            } />
            : <Route path={'/login'} element={<LoginId />} />
          }
          <Route path={'/agents'} element={<Login open />} />
          {loggedOnce ? (
            autoriseRoute.map((e, i) => (
              <Route
                exact
                key={i}
                {...e}
                element={<Suspense fallback={<div>Chargement en cours...</div>}>
                  <e.component
                    enabled={autoriseRoute}
                    {...props}
                    {...e.componentProps}
                  />
                </Suspense>}
              />
            ))
          ) : (
            <Route path='*' element={
              <Navigate
                to={'/login'}
                state={{
                  from: props.location === '/login'
                    ? '/'
                    : props.location,
                }}
              />
            } />
          )}
          <Route path='*' element={<Page404 />} />
        </Routes>
        <Notifications />
        <ActionNotification />
        <DialogManager />
        <LoadingManager />
      </ThemeProvider>
    </div>
  );
});

export default compose(
  connect(mapStateToProps),
  withMediaQuery()
)(Dashboard);
