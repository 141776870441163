import React from 'react';
import CustomDialog from '../Dialogs/CustomDialog';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const LoginDialog = ({ open, validate, getPassword,
  tel, pass, handleChange }) =>
  <CustomDialog
    open={open}
    disableBackdropClick
    disableEscapeKeyDown
    name="Connectez-Vous"
    subTitle={'Merci d\'entrer votre numéro de téléphone et mot de passe'}
    actions={[
      <Button key={0} onClick={validate} style={{ fontSize: 'large' }}
        color="primary">
        Valider
      </Button>,
      <Grid key={1} container style={{ textAlign: 'center' }}>
        <Grid item xs={6}>
          <Button onClick={getPassword} style={{ fontSize: 'smaller' }}
            color="primary">
        Mot de passe oublié
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={getPassword} style={{ fontSize: 'smaller' }}
            color="primary">
        Premiere connexion
          </Button>
        </Grid>
      </Grid>
    ]}
    actionStyle={{
      justifyContent: 'center',
      flexDirection: 'column'
    }}
  >
    <TextField
      onChange={handleChange('tel')}
      autoFocus
      margin="dense"
      id="tel"
      label={'Numéro de téléphone'}
      type="text"
      value={tel}
      inputProps={{
        style: { textAlign: 'center' }
      }}
      required
      fullWidth
    />
    <TextField
      onChange={handleChange('pass')}
      autoFocus
      margin="dense"
      id="pass"
      label={'Mot de passe'}
      type="password"
      value={pass}
      inputProps={{
        style: { textAlign: 'center' }
      }}
      required
      fullWidth
    />
  </CustomDialog>;

export default LoginDialog;
