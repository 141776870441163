import {
  SET_DIALOG,
  SET_DIALOG_CONTENT_PROP,
  SET_DIALOG_PROP,
} from '../constants/ActionTypes';

export const setDialog = ({
  name = '',
  open = false,
  hideClose = false,
  dialogProps = {},
  contentProps = {},
  actions = []
} = {}) => ({
  type: SET_DIALOG,
  name,
  open,
  hideClose,
  dialogProps,
  contentProps,
  actions
});

export const closeDialog = () => setDialog();

export const setDialogProps = (key) => (value) => ({
  type: SET_DIALOG_PROP,
  key,
  value,
});

export const setDialogContentProps = (contentProps = {}) => ({
  type: SET_DIALOG,
  contentProps
});

export const setDialogActions = (actions = []) => ({
  type: SET_DIALOG,
  actions
});

export const setDialogContentProp = key => value => ({
  type: SET_DIALOG_CONTENT_PROP,
  key,
  value
});

export const setDialogComment = (action, contentProps) =>
  setDialog({
    name: 'CommentDialog',
    open: true,
    contentProps,
    actions: [
      {
        children: 'Commenter',
        onClick: action
      }
    ]
  });
