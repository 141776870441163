import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import ExitToApp from '@mui/icons-material/ExitToApp';
import SvgIcon from '@mui/material/SvgIcon';
import logo from '../../public/lesbonsartisansdef.png';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { COMP } from '@lba-dev/package.local-globals/dossier';
import { Call } from '@mui/icons-material';
import { assistanceDialog } from '../actions/proposition';

const regex = /^(\/missions(?:\/(ongoing|history|news|\d+))?|\/?)$/;
const missionRegex = /^\/missions\/\d+$/;

const mainPages = [
  '/',
  '/missions',
  '/missions/ongoing',
  '/missions/history',
  '/missions/news',
];

const useStyles = makeStyles()({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  img: {
    paddingLeft: 16,
  },
});


const ButtonHeader = ({
  user, navigate, externalPage, pageName
}) => {
  const pagesWithProfile =
    mainPages.some(() => regex.test(pageName)) ||
    externalPage
  const pagesWithBackButton = pageName === '/documents' ||
    missionRegex.test(pageName);
  if (pagesWithBackButton) {
    return <IconButton
      onClick={() => navigate(-1)}
      children={<ArrowBackIcon color="primary" />}
    />
  } else if (pagesWithProfile && user) {
    return <div>
      <Button color="primary"
        component={Link}
        id="profileButton"
        to={'/profile'}>
        <Badge
          color="secondary"
          badgeContent=" "
          variant="dot"
          invisible={user._dossier === COMP}
        >
          <AccountCircleIcon />
        </Badge>
      </Button>
      <Button color="primary"
        id='AssistanceButton'
        onClick={() => assistanceDialog()}
      >
        <Call />
      </Button>
    </div>
  }
  return <Button color="primary" component={Link} to={'/'}>
    <SvgIcon>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  </Button>;
}

const Header = ({
  navigate, deconnexion, user, pageName
}) => {
  const { classes } = useStyles()
  const externalPage = (location.pathname.includes('/sign/') &&
    !location.pathname.includes('/artisans')) ||
    location.pathname.includes('candidatRelance') ||
    location.pathname.includes('candidatCancel')
  return (
    <AppBar
      position="relative"
      color="default"
      enableColorOnDark
    >
      <Toolbar className={classes.toolbar}>
        <ButtonHeader
          pageName={pageName}
          user={user}
          navigate={navigate}
          externalPage={externalPage}
        />
        <img
          src={logo}
          alt="logo"
          onClick={() => navigate('/')}
          className={classes.img}
          width={100}
        />
        {!externalPage ? (
          <div>
            <Button color="primary"
              id='faqButton'
              component={Link}
              to={'/faq'}>
              <QuestionMarkIcon />
            </Button>
            <Button color="primary" onClick={() => deconnexion()}>
              <ExitToApp />
            </Button>
          </div>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
