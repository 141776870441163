import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
const useStyles = makeStyles()({
  grid: {
    height: 500,
  },
  text: {
    fontSize: 'x-large',
  },
  button: {
    textAlign: 'center'
  }
});

const Page404 = () => {
  const { classes } = useStyles();
  return (
    <Grid
      className={classes.grid}
      container
      spacing={2}
      justifyContent="center"
      alignContent="center"
    >
      <Grid
        item
        xs={12}
        lg={12}
        sm={12}
        children={
          <Typography
            className={classes.text}
            align="center"
            variant="h1"
            gutterBottom
            children="PAGE INTROUVABLE"
          />
        }
      />
      <Grid
        item
        xs={12}
        lg={12}
        sm={12}
        className={classes.button}
        children={
          <Button
            variant="outlined"
            component={Link}
            to={'/'}
            size="large"
            color="primary"
            children={'Page d\'accueil'}
          />
        }
      />
    </Grid>
  );
};

export default (Page404);
