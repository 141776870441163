import Login from '../components/Login';
import React, { PureComponent } from 'react';
import api from '../api';
import { withStyles } from 'tss-react/mui';
import { ThemeProvider } from '@mui/material/styles';
import { login as loginAction } from '../actions/auth';
import { compose } from 'redux';
import { login as theme } from '../theme';
import notifSystem from '../notifSystem';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import logo from '../../public/home.jpg';
import { connect } from 'react-redux';
import withRouter from '../components/Hoc/withRouter';

const styles = {
  main: {
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    zIndex: 300,
    transition: 'opacity 0.5s',
  },
  center: {
    margin: 'auto',
  },
  img: {
    width: '50%',
    height: '100%',
    'background-image': `url(${logo})`,
    'background-size': 'cover',
    'background-repeat': 'non-repeat',
    'background-position': 'right',
  },
};

export class LoginView extends PureComponent {
  state = {
    error: '',
  };

  login = async (login, password) => {
    try {
      const res = await api.login.post({
        login,
        pass: password,
      });
      if (res.statusCode() === 200) {
        const { token } = res.body().data();
        this.props.dispatch(loginAction(token));
        this.props.navigate('/');
        return true;
      }
    } catch (error) {
      notifSystem.error(error.name, error.message);
      this.setState({
        error,
      });
    }
    return false;
  };

  render() {
    const { classes } = this.props;
    const style = { opacity: 1 };
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.main} style={style}>
          <Grid className={classes.center} item xs={12} md={12} lg={5}>
            <Login login={this.login} error={this.state.error} />
          </Grid>
          <Hidden mdDown>
            <div src={logo} alt="logo" className={classes.img} />
          </Hidden>
        </div>
      </ThemeProvider>
    );
  }
}

export default compose(
  withRouter, connect()
)(withStyles(LoginView, styles));
