import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

export default class CustomDialog extends Component {
  constructor() {
    super();
    this.state = {
      visible: false
    };
  }
  render() {
    const {
      closeDisagreeListener = f => f,
      children,
      open,
      name,
      actions,
      maxWidth,
      fullWidth,
      subTitle,
      disableBackdropClick,
      disableEscapeKeyDown,
      actionStyle
    } = this.props;
    return (
      <div>
        <Dialog
          onClose={(e, reason) => {
            switch (true) {
              case reason === 'escapeKeyDown' && !!disableEscapeKeyDown:
                closeDisagreeListener();
                break;
              case reason === 'backdropClick' && !!disableBackdropClick:
                closeDisagreeListener();
                break;
            }
          }}
          onEscapeKeyDown={closeDisagreeListener}
          open={open}
          maxWidth={maxWidth}
          fullWidth={fullWidth}
        >
          {name && <DialogTitle>{name}</DialogTitle>}
          <DialogContent>
            {subTitle ?
              <DialogContentText>{subTitle}</DialogContentText> : null}
            {children}
          </DialogContent>
          <DialogActions style={actionStyle}>
            {actions}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

CustomDialog.defaultProps = {
  agreeText: 'Valider',
  disagreeText: 'Annuler',
  actions: [],
  maxWidth: 'md',
  fullWidth: true,
};
