import React from 'react';

import { withStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Clear from '@mui/icons-material/Clear';

const styles = {
  tableCellName: {
    padding: '4px 15px 4px 10px',
  },
  tableCellIcon: {
    verticalAlign: 'middle',
    padding: '4px 15px 4px 10px!important',
    textAlign: 'right'
  },
  titleCell: {
    padding: 0,
    borderBottom: '0px solid #fff',
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    paddingTop: 10,
  },
};

const renderFiles = ({ classes, body, deleteFile }) =>
  body.map((e, i) => (
    <TableRow key={i} classes={classes.fileRow}>
      <TableCell className={classes.tableCellName}>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={e.link}
        >
          {`${e.fileName}`}
        </a>
      </TableCell>
      <TableCell className={classes.tableCellIcon}>
        {
          typeof deleteFile === 'function' ?
            <Clear color="secondary"
              onClick={() => deleteFile(e.link)}
            /> : ''
        }
      </TableCell>
    </TableRow>
  ));

const FilesTable = ({ classes, body, checkDocumentNumber,
  fileType, deleteFile }) =>
  <Table>
    {body.length ?
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} className={classes.titleCell}>
            {checkDocumentNumber && <Typography
              className={classes.title}
              color="primary"
              children='Fichiers ajoutés :'
            />}
          </TableCell>
        </TableRow>
      </TableHead> : null}
    <TableBody>
      {body.length ?
        renderFiles({ classes, body, deleteFile }) :
        <TableRow>
          <TableCell colSpan={2} className={classes.titleCell}>
            <Typography
              className={classes.title}
              color="primary"
              children={
                checkDocumentNumber &&
                  checkDocumentNumber(fileType) === 0 ?
                  'Le nombre max de fichiers est atteint' :
                  'Aucun fichier ajouté'}
            />
          </TableCell>
        </TableRow>}
    </TableBody>
  </Table>;


FilesTable.defaultProps = {
  header: [],
  body: [],
  handleChange: e => e,
  checked: []
};

export default withStyles(FilesTable, styles);
