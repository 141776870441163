import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp, setDialogProps } from '../../actions/dialog';
import TextField from '@mui/material/TextField';
import { Button, Grid } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CallIcon from '@mui/icons-material/Call';
import { openWhatsApp } from '../../utils/links';
import company from '@lba-dev/package.local-globals/company';

const setComments = setDialogContentProp('comments');
const setTitle = setDialogProps('title');

const mapStateToProps = ({
  dialog: {
    contentProps: { comments },
  },
}) => ({
  comments,
});

const mapDispatchToProps = {
  setComments,
  setTitle,
};

const numbers = [
  {
    name: 'comptabilité',
    number: company.lines.telComptaFournisseur,
  },
  {
    name: 'partenariat',
    number: company.lines.telPartena,
  },
  {
    name: 'commercial',
    number: company.lines.telCommerc,
  },
  {
    name: 'Achat',
    number: company.lines.telAchat,
  }
]

const SendSignal = ({ comments, setComments, setTitle }) => {
  const [isCall, setIsCall] = useState(false);
  const onChangeText = ({ target: { value } }) => setComments(value);
  const onSetCall = () => {
    setIsCall(!isCall);
    setTitle('Merci de préciser en quelques mots votre demande');
  };
  const onWhatsapp = () => openWhatsApp();
  const [showNumber, setShowNumber] = useState(false);
  if (!isCall && !showNumber) {
    return <Grid container style={{ textAlign: 'center' }} spacing={2}>
      <Grid item xs={12} sm={4}>
        <Button
          variant="outlined"
          fullWidth
          startIcon={<WhatsAppIcon />}
          onClick={onWhatsapp}
        >
          WhatsApp
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          startIcon={<SupportAgentIcon />}
          variant="outlined"
          onClick={onSetCall}
          fullWidth
        >
          Être rappelé
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          variant="outlined"
          fullWidth
          startIcon={<CallIcon />}
          onClick={() => setShowNumber(true)}
        >
          Nous appeler
        </Button>
      </Grid>
    </Grid>
  } else if (isCall) {
    return <TextField
      onChange={onChangeText}
      autoFocus
      minRows={5}
      multiline
      margin="dense"
      id="comment"
      label={''}
      type="text"
      value={comments}
      inputProps={{
        style: { textAlign: 'center' },
      }}
      required
      fullWidth
    />
  } else if (showNumber) {
    return <Grid container justifyContent="center" spacing={2}>
      {numbers.map((e, i) => (
        <Grid key={i} item xs={8} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            size="large"
            aria-label={`Appel ${e.name}`}
            href={`tel:${e.number}`}
            fullWidth
            style={{
              fontSize: '0.8475rem',
            }}
          >
            {e.name.toUpperCase()}
          </Button>
        </Grid>
      ))}
    </Grid>
  }
  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(SendSignal);
