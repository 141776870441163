import React from 'react';
import { connect } from 'react-redux';
import PropositionUploadButton from './PropositionUploadButton';

const mapStateToProps = ({
  dialog: {
    contentProps: {
      photos,
      setData,
      deleteDoc,
      statusColor,
      id,
      invoiceTitle,
      type
    },
  },
}) => ({
  photos,
  setData,
  deleteDoc,
  statusColor,
  id,
  invoiceTitle,
  type
});

const SendBonInter = ({
  setData,
  deleteDoc,
  photos,
  statusColor,
  id,
  invoiceTitle,
  type
}) => (
  <PropositionUploadButton
    key={3}
    title={invoiceTitle}
    type={type}
    photos={photos}
    setData={setData}
    deleteFile={deleteDoc}
    statusColor={statusColor}
    id={id}
  />
);

export default connect(mapStateToProps)(SendBonInter);
