import {
  CheckCircle,
  Warning,
  Error,
  Info
} from '@mui/icons-material';

//Look for Check / CheckCircle to look more consistant
export default {
  critical: { color: '#ef4444', icon: Error },
  error: { color: '#ef6644', icon: Error },
  warning: { color: '#efaa33', icon: Warning },
  success: { color: '#66aa33', icon: CheckCircle },
  info: { color: '#4488ab', icon: Info }
};
