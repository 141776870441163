import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { assistanceMessage } from '../../constants/sms';
import { Phone, WhatsApp } from '@mui/icons-material';
import company from '@lba-dev/package.local-globals/company';
import { openWhatsApp } from '../../utils/links';

const mapStateToProps = ({
  dialog: {
    contentProps: { inter },
  },
  auth,
}) => ({
  inter,
  auth: auth.decodedToken,
});

const numbers = [
  {
    name: 'comptabilité',
    number: company.lines.telComptaFournisseur,
  },
  {
    name: 'partenariat',
    number: company.lines.telPartena,
  },
  {
    name: 'commercial',
    number: company.lines.telCommerc,
  },
  {
    name: 'Achat',
    number: company.lines.telAchat,
  }
]

const Assistance = ({ inter }) => {
  const [showNumber, setShowNumber] = useState(false);

  return (
    <Grid container spacing={2} xs={12}>
      <Grid container item spacing={3} justifyContent="center" padding={1}>
        {showNumber ? numbers.map((e, i) => (
          <Grid key={i} item xs={8} display="flex" justifyContent="center">
            <Button
              variant="outlined"
              size="large"
              aria-label={`Appel ${e.name}`}
              href={`tel:${e.number}`}
              fullWidth
              style={{
                fontSize: '0.8475rem',
              }}
            >
              {e.name.toUpperCase()}
            </Button>
          </Grid>)) :
          <>

            <Grid item xs={6} display="flex" justifyContent="center">
              <Button
                variant="contained"
                size="large"
                fullWidth
                aria-label="Message support"
                onClick={() =>
                  openWhatsApp(inter ?
                    encodeURIComponent(assistanceMessage(inter)) : '')
                }
                target="_blank"
                startIcon={<WhatsApp />}
                style={{
                  fontSize: '0.8475rem',
                }}
              >
            Whatsapp
              </Button>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="center">
              <Button
                variant="contained"
                size="large"
                aria-label="Appel support"
                onClick={() => setShowNumber(true)}
                fullWidth
                startIcon={<Phone />}
                style={{
                  fontSize: '0.8475rem',
                }}
              >
            Appel
              </Button>
            </Grid>
          </>
        }
      </Grid>
    </Grid>
  )
};

export default connect(mapStateToProps)(Assistance);
