import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

class CustomTextField extends PureComponent {
  constructor() {
    super();
    this.setData = this.setData.bind(this);
  }
  setData(e) {
    const { setData, path, capsLocked, type } = this.props;
    let value;
    if (type === 'number') {
      value = parseFloat(e.target.value);
    } else {
      value = capsLocked ? e.target.value.toUpperCase() : e.target.value;
    }
    if (this.props.elemPos >= 0) {
      setData(path, value, this.props.elemPos);
    } else {
      setData(path, value);
    }
  }

  render() {
    const {
      xs,
      sm,
      md,
      lg,
      xl,
      grid,
      rows,
      requiredText,
      classGrid,
      stylesInputs,
      ...otherProps
    } = this.props;
    delete otherProps.setData;
    delete otherProps.path;
    delete otherProps.capsLocked;
    const input = (
      <TextField
        onChange={this.setData}
        fullWidth
        onContextMenu={(e) => {
          e.stopPropagation();
        }}
        inputProps={{
          style: {
            ...stylesInputs,
            textTransform: otherProps.texttransform || 'uppercase',
          },
        }}
        multiline={true}
        minRows={rows}
        {...otherProps}
        required={requiredText}
      />
    );
    if (grid) {
      return (
        <Grid
          item
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
          className={classGrid}
        >
          {input}
        </Grid>
      );
    }
    return input;
  }
}

CustomTextField.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  setData: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.objectOf([PropTypes.string, PropTypes.number])),
    PropTypes.string,
    PropTypes.number,
  ]),
  stylesInputs: PropTypes.object,
  grid: PropTypes.bool,
  capsLocked: PropTypes.bool,
  classGrid: PropTypes.string,
};

export default CustomTextField;
