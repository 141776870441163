import rootReducer from './reducers';
import { login } from './actions/auth';

import { legacy_createStore as createStore } from 'redux';

const store = createStore(
  rootReducer,
  process.env.NODE_ENV !== 'production' &&
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const token = window.localStorage.getItem('token');
if (token) {
  store.dispatch(login(token));
}

export default store;
