import notifStatus from './constants/notifStatus';

class Notifs {
  constructor() {
    this.count = 0;
    this.subscriber = null;
    this.notifs = [];
  }
  critical(title, msg, timeout) {
    return this.send({ title, msg, type: notifStatus.critical, timeout });
  }
  error(title, msg, timeout) {
    return this.send({ title, msg, type: notifStatus.error, timeout });
  }
  warning(title, msg, timeout) {
    return this.send({ title, msg, type: notifStatus.warning, timeout });
  }
  success(title, msg, timeout) {
    return this.send({ title, msg, type: notifStatus.success, timeout });
  }
  info(title, msg, timeout) {
    return this.send({ title, msg, type: notifStatus.info, timeout });
  }
  subscribe(cb) {
    if (typeof cb !== 'function') {
      throw new Error('Not a function');
    }
    if (this.subscriber !== null) {
      throw new Error('Someone has already subscribe to notifications');
    }
    this.subscriber = cb;
    return () => {
      this.subscriber = null;
    };
  }
  send(notif) {
    const finalNotif = { ...notif, id: this.count++ };
    this.notifs.push(finalNotif);
    this.subscriber();
    return (finalNotif);
  }
  consume() {
    return this.notifs.shift();
  }
  bindStore(store) {
    this.store = store;
  }
}

export default new Notifs();
