import React, { Component } from 'react';

import CloudUpload from '@mui/icons-material/CloudUpload';
import { closeDialog } from '../../actions/dialog';

import api from '../../api';
import {
  attachFile
} from '@lba-dev/package.local-globals/utils';
import notifSystem from '../../notifSystem';
import {
  data as filesTypes,
} from '@lba-dev/package.local-globals/docTypes';
import { fromJS } from 'immutable';
import FilesTable from '../Utils/filesTable';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';

import store from '../../store';

const path = ['dropbox'];


const promiseAttachFile = file => attachFile(file);
class PropositionUploadButton extends Component {
  uploadFunction = e => {
    try {
      const { setData, id, photos, type } = this.props;
      const files = e.target.files;
      const name = `${filesTypes.find(e => e._id === type).name}-${photos.size +
        1}`;
      if (files.length) {
        promiseAttachFile(files[0]).then(({ data, type: extention }) =>
          api.interventions
            .custom(`upload/${id}`)
            .post({
              fileName: `${name}.${extention.split('/')[1]}`,
              file: data,
              type,
            })
            .then((res) => {
              Promise.resolve(
                setData(
                  path,
                  photos.push(
                    fromJS({
                      link: res.body().data().link,
                      fileName: name,
                      type,
                    })
                  )
                )
              );
            })
        );
      }
      store.dispatch(closeDialog());
    } catch (error) {
      notifSystem.error('Erreur', 'L\'image n\'a pas été récupérée');
    }
  };

  render() {
    const {
      statusColor,
      photos,
      deleteFile,
      type,
      title,
      limit = 4
    } = this.props;
    const inputMax = photos.filter((e) => e.get('type') === type).size >= limit;
    return (
      <div>
        <input
          disabled={inputMax}
          type="file"
          id={`proposition-${type}`}
          style={{ display: 'none' }}
          accept="image/*,.doc,.docx,application/pdf"
          onChange={this.uploadFunction}
        />
        <label htmlFor={`proposition-${type}`}>
          <Button
            disabled={inputMax}
            fullWidth
            variant="contained"
            component="span"
            color="primary"
            style={{ backgroundColor: statusColor }}
          >
            <Typography
              variant="body1"
              style={{
                color: grey[50],
                textAlign: 'center',
              }}
              children={!inputMax ? title : 'Limite de fichier atteint'}
            />
            <CloudUpload />
          </Button>
        </label>
        <FilesTable
          deleteFile={deleteFile}
          fileType={type}
          body={photos.filter((e) => e.get('type') === type).toJS()}
          checkDocumentNumber={() => limit}
        />
      </div>
    );
  }
}

export default PropositionUploadButton;
