import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';

class CustomSelect extends PureComponent {
  constructor() {
    super();
    this.setData = this.setData.bind(this);
  }
  setData(e) {
    const { setData, path } = this.props;
    setData(path, e.target.value);
  }
  render() {
    const {
      xs,
      sm,
      md,
      lg,
      xl,
      label,
      children,
      grid,
      requiredText,
      gridProps = {},
      ...otherProps
    } = this.props;
    delete otherProps.setData;
    delete otherProps.path;
    const input = (
      <FormControl required={requiredText} fullWidth>
        {label ? <InputLabel>{label}</InputLabel> : null}
        <Select
          label={label}
          onChange={this.setData}
          {...otherProps}
        >
          {children}
        </Select>
      </FormControl>
    );
    if (grid) {
      return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} {...gridProps}>
          {input}
        </Grid>
      );
    }
    return input;
  }
}

CustomSelect.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  setData: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.objectOf([
      PropTypes.string,
      PropTypes.number
    ])),
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  grid: PropTypes.bool
};


export default CustomSelect;
